import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { crmLostFoundRequest, crmLostFoundFailure, submitFormSuccess } from "../slice/crmLostFoundSlice";
import { config } from "../../../../src/config/global";
const CRM_Service_URL = config.MYTHAI_CRM_LOST_FOUND;
const accessToken = sessionStorage.getItem("accesstoken");
const CrmLostFoundData = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken
    },
    loader: true
  };
  const response = await api.post(CRM_Service_URL, payload, data);
  return response;
};
function* getCrmLostFoundData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(CrmLostFoundData, payload);
    yield put(submitFormSuccess(response.data));
  } catch (error) {
    yield put(crmLostFoundFailure(error));
  }
}
export function* watchLostFoundData() {
  yield takeLatest(crmLostFoundRequest.type, getCrmLostFoundData);
}
import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { airportDataRequest, getAirportDataSuccess, getAirportDataFailure } from "../slice/bookingWidgetSlice";
import { config } from "../../../config/global";
const AIRPORT_API_URL = config === null || config === void 0 ? void 0 : config.AIRPORT_MAIN_URL;
function* getAirportData(action) {
  try {
    let data = {
      mode: "no-cors",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": action.payload
      },
      body: {}
    };
    const response = yield call(api.get, AIRPORT_API_URL, data);
    yield put(getAirportDataSuccess(response.data));
  } catch (error) {
    yield put(getAirportDataFailure(error.message));
  }
}
export function* watchAirportData() {
  yield takeLatest(airportDataRequest.type, getAirportData);
}
import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { baggageClaimSuccess, baggageClaimFailure, baggageClaimRequest } from "../slice/baggageClaimSlice";
import { config } from "../../../../src/config/global";
const BAGGAGE_CLAIM_URL = config.BAGGAGE_CLAIM;
const accessToken = sessionStorage.getItem("accesstoken");
const memberId = sessionStorage.getItem("memberId");
const BaggageClaimData = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
      memberId
    },
    loader: true
  };
  const response = await api.post(BAGGAGE_CLAIM_URL, payload, data);
  return response;
};
function* getBaggageClaimData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(BaggageClaimData, payload);
    yield put(baggageClaimSuccess(response.data));
  } catch (error) {
    yield put(baggageClaimFailure(error));
  }
}
export function* watchBaggageClaim() {
  yield takeLatest(baggageClaimRequest.type, getBaggageClaimData);
}